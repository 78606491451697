<template>
  <div class="footer p-4 md:py-32 md:px-64 bg-dcblue text-white">
    <div class="w-64 mx-auto text-center mb-8 md:mb-48">
      <h1 class="text-xl md:text-175 font-semibold mb-3">Want more?</h1>
      <p class="md:text-xl mb-6 md:mb-12">We give you an access demo to try <strong>MARINE</strong>REGULATIONS yourself.</p>
      <router-link to="/signup" class="px-6 md:px-12 py-2 md:py-5 bg-dcgreen text-white md:text-lg tracking-wide font-bold uppercase">Give it a try</router-link>
    </div>

    <div class="mx-auto text-center text-xs md:text-sm  mb-2 md:mb-5">
      <router-link to="/about" class="uppercase mx-2 md:mx-8">About us</router-link>
      <router-link to="/terms" class="uppercase mx-2 md:mx-8">Terms of Use</router-link>
      <router-link to="/privacy-policy" class="uppercase mx-2 md:mx-8">Privacy Policy</router-link>
      <router-link to="/support" class="uppercase mx-2 md:mx-8">Support</router-link>
    </div>

    <hr class="border-solid border-dcbordergray opacity-25"/>

    <div class="text-sm mx-auto text-center mt-2 md:mt-6">
      support@dandocs.com • Symfonivej 18 • DK-2730 Herlev
    </div>

    <div class="mx-auto text-center mt-2 md:mt-6">
      <img class="inline" src="@/assets/otg.svg" alt="Part of OCEAN Technologies Group" width="192">
    </div>
  </div>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>
</style>
